class Data {
    async loadMatches(market, league, matches, bets) {
        let data = await fetch("https://valuebase.io/api/sef/nlwidget2?market=" + market + "&league=" + league + "&matches=" + matches)
            .then(res => (res.ok ? res : Promise.reject(res)))
            .then(res => res.json());

        data.matches.forEach(match => {
            var bestBB = Math.max.apply(Math, match.bets.map(function (o) { return o.bb; }));
            var bestBet = match.bets.find(function (o) { return o.bb === bestBB; });

            if (match.homeTeamCode === null)
                match.homeTeamCode = match.homeTeam.replace(' ', '').substring(0, 3).toUpperCase();

            if (match.awayTeamCode === null)
                match.awayTeamCode = match.awayTeam.replace(' ', '').substring(0, 3).toUpperCase();

            bestBet.best = true;
        });

        return data;
    }
}

export default new Data();