import React, { useState } from 'react';

const Match = ({ props, index, parentCallback }) => {
  const [active] = useState(true);

  return (
    <div className={`match ${active ? 'active' : 'disabled'}`} data-match-id="@match.UnibetId">

      <div className="teams">
        <div className="team">
          <img className="team-logo" alt="" src={`/images2/${props.homeId}.png`} onError={(event) => { event.target.onerror = null; event.target.src = '/images2/home.png'}} />
          <div className="team-code">{props.homeTeamCode}</div>
          <div className="team-name">{props.homeTeam}</div>
        </div>
        <div className="team">
          <img className="team-logo" alt="" src={`/images2/${props.awayId}.png`} onError={(event) => { event.target.onerror = null; event.target.src = '/images2/away.png'}} />
          <div className="team-code">{props.awayTeamCode}</div>
          <div className="team-name">{props.awayTeam}</div>
        </div>
      </div>
      <div className="bets">
        {props.bets.map((bet, index) => (
          <div key={bet.id} className={`bet ${bet.best ? 'active' : 'disabled'}`}>
            <div className="bet-button">
              <div className="bet-type">{bet.type}</div>
              <div className="bet-odds">{bet.odds}</div>
            </div>
            <div className="bb">
              <div className="bb-amount">{bet.bb}%</div>
              <div className="bb-bar-container">
                <div className="bb-bar" style={{ width: bet.bb + "%" }}></div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>

  );
}

export default Match;