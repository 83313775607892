import React, { } from 'react';

const Total = ({ props, timestamp }) => {
    let outcomes = [];
    //let totalOdds = 1.0;

    props.matches.forEach(match => {
        match.bets.forEach(bet => {
            if (bet.best === true) {
                outcomes.push(bet.id);
                //totalOdds *= bet.odds;
            }
        });
    });

    const placeBets = () => {
        let url = "https://b1.trickyrock.com/redirect.aspx?pid=86010803&bid=37517&redirecturl=https://www.unibet.nl/betting/sports/event/100000%23?coupon=combination|" + outcomes + "||replace";
        window.open(url, '_blank').focus();
        //window.top.location.href = url;
    };

    return (
        <div className="total">
            <div className="total-left">
                <div className="total-label">
                    Totale odds:
        </div>
            </div>
            <div className="total-right">
                <div className="total-odds">
                    {props.odds}
                </div>
                <button className='place-bets' onClick={placeBets}>Speel mee</button>
            </div>
        </div>
    );
}

export default Total;